
import Vue from "vue";

import {
  billingChangeRequestMapState,
  billingChangeRequestMapActions,
  billingChangeRequestMapGetters
} from "@/store/modules/billingchangerequest";
import { companiesMapGetters } from "@/store/modules/companies";
import { authMapGetters } from "@/store/modules/auth";
import { printSingleChangeRequest } from "@/helpers/downloadLossForm";

import { object } from "dot-object";
interface IBilling {
  errorMessage: string;
  successMessage: string;
  resourceNotFound: boolean;
  didInit: boolean;
  billChangeRequestData: any;
  pageDataInitialized: boolean;
}

export default Vue.extend({
  name: "BillingChangeRequestDetailsViewLegacy",

  data(): IBilling {
    return {
      errorMessage: "",
      successMessage: "",
      resourceNotFound: false,
      didInit: false,
      billChangeRequestData: {},
      pageDataInitialized: false
    };
  },
  components: {
    // DataView
    ViewChangeRequestInsurerDetails: () =>
      import(
        "@/components/ChangeRequests/ViewChangeRequestInsurerDetailsLegacy.vue"
      ),
    ViewChangeRequestPolicyDetails: () =>
      import(
        "@/components/ChangeRequests/ViewChangeRequestPolicyDetailsLegacy.vue"
      ),
    DisplayAttachment: () =>
      import("@/components/DataView/DisplayAttachment.vue")
  },
  computed: {
    ...authMapGetters(["getCurrentUser"]),
    billingChangeRequestId(): string {
      return this.$route.params.billingchangerequest_id;
    },
    ...billingChangeRequestMapState(["makingApiRequest", "editing"]),
    ...billingChangeRequestMapGetters(["getBillingChangeRequestById"]),
    original(): any {
      return this.getBillingChangeRequestById(this.billingChangeRequestId);
    },
    ...companiesMapGetters(["companies"]),
    agentApplicationID(): any {
      return this.$route.params.agentApplicationID;
    },
    topButtonSecondaryItems(): {
      text: string;
      key: string;
      subItems: any[];
    } | null {
      return {
        text: "Actions",
        key: "actions",
        subItems: [
          { title: "Back", command: "back" },
          { title: "Print", command: "print" }
        ]
      };
    }
  },
  created() {
    this.initializePageData();
  },
  methods: {
    ...billingChangeRequestMapActions(["getBillingChangeRequest"]),
    getValue(data: object, property: string): string {
      return data ? `${data}.${property}` : "";
    },
    // function to get the property of the object
    getProperty(data: object, property: keyof typeof object): string {
      return data ? data[property] : "";
    },

    async initializePageData() {
      try {
        await this.getBillingChangeRequest(this.billingChangeRequestId);
        const { data, ...rootFields } = this.editing as any;
        this.billChangeRequestData = {
          ...rootFields,
          ...data
        };
        this.pageDataInitialized = true;
      } catch (error) {
        this.resourceNotFound = true;
        this.$bugSnagClient.notify(error);
      }
    },
    onToolbarItemSelected(action: string): void {
      switch (action) {
        case "back":
          this.$router.push(`/billingchangerequests`).catch(() => {});
          break;
        case "print":
          this.printCallback();
          break;
        default:
          break;
      }
    },
    convertBooleanToText(value: boolean): string {
      return value ? "Yes" : "No";
    },
    toTitleCase(str: string): string {
      return str.replace(/([A-Z])/g, " $1").replace(/^./, function(str) {
        return str.toUpperCase();
      });
    },
    async printCallback() {
      const policyPrefix =
        this.editing &&
        this.editing.data &&
        this.editing.data.policyInfo &&
        this.editing.data.policyInfo.policyPrefix
          ? this.editing.data.policyInfo.policyPrefix
          : undefined;
      await printSingleChangeRequest.bind(this)({
        changeRequestIds: this.editing?._id,
        policyPrefix,
        printAction: "billingChangeRequest/printBillingChangeRequest"
      });
    }
  }
});
